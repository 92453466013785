const gaiden = [
    `Ease`,
    `Interceptor`,
    `Cornercraft`,
    `Expedition`,
    `Flippers`,
    `Pulse`,
    `Slopeshift`,
    `Safety Pipe`,
    `Stair Valley`,
    `Margin`,
    `Hidden Hills`,
    `Emergency Brake`,
    `Landing Gear`,
    `Parkour`,
    `Whisk`,
    `Kickflip`,
    `Multi Spring`,
    `Cubbyholes`,
    `Unwind`,
    `Shrink Ray`,
    `Cycle Hit`,
    `Boost Bridges`,
    `Suspension`,
    `Quaketray`,
    `Aligner`,
    `Master of None`,
    `Ringfield`,
    `Trinity`,
    `Battle Royale`,
    `Actual Guillotine`,
    `Mandoline`,
    `Tug`,
    `Whirlers`,
    `Diaphragm`,
    `Roundabout`,
    `Catenary`,
    `Pattern Prism`,
    `Turbine`,
    `Piercers`,
    `Emitter`,
    `Dragonfly`,
    `Sway`,
    `Jumpplex`,
    `Drop of Doom`,
    `Perimeter`,
    `Rotary`,
    `Snakeskin`,
    `Range`,
    `Departure`,
    `Warp Dreams`,
    `Tripwire`,
    `Equilibrium`,
    `Rook`,
    `Rigidify`,
    `Binary Launchers`,
    `Pyramid Run`,
    `Assembly`,
    `Circus`,
    `Seismic`,
    `Slot Machine`,
    `Ikaruga`,
    `Focus Breaker`,
    `Polarity`,
    `Carpets`,
    `Phantasm`,
    `Upstream`,
    `Rebuild`,
    `Siege`,
    `Albatross`,
    `Gaokao`,
    `Lock On`,
    `Recoil`,
    `Derelict`,
    `Gradient Descent`,
    `Raze`,
    `Gyroscope`,
    `Revision`,
    `Painted Pipe`,
    `Visionary`,
    `Factory`,
    `Demolition`,
    `Twisty Triad`,
    `Spinways`,
    `Strum`,
    `Antagonizer`,
    `Axle`,
    `Colossus`,
    `Fallout Zone`,
    `Lightspeed`,
    `Apparatus`,
    `Exodus`,
    `Pandora's Box`,
    `Genesis`,
    `Ausdauer`,
    `Intermezzo`,
    `Trick Room`,
    `Ouroboros`,
    `Red Sea`,
    `Shadow Tag`,
    `Curtain Call`,
    `Enigma`,
    `Master of All`,
    `Maelstrom`,
    `Tartarus`,
    `Sisyphus`,
    `Acheron`,
    `Styx`,
    `Pride`,
    `Babel`,
    `Gauntlet`,
    `The Grand Finale`,
];

const stardust = [
    `Impression`,
    `Leany Bridges`,
    `Slope Step`,
    `Showerhead`,
    `Palette Choice`,
    `Reveal`,
    `Compass Rose`,
    `Double Time`,
    `Klein Bottle`,
    `Ice Tray`,
    `Ripples`,
    `Polyominos`,
    `Color Channels`,
    `Dragsters`,
    `Scatterbrain`,
    `Liftoff`,
    `Pan Pipes`,
    `Cone Flake`,
    `Pitfall`,
    `Cloudscape`,
    `Shatter`,
    `Orbital Armada`,
    `Sweepers`,
    `Solar Vessel`,
    `Monkey Grill`,
    `Tick-Flip`,
    `Gridlock`,
    `Monorails`,
    `Power Grid`,
    `Detonation`,
    `Chevrons`,
    `Wraparound`,
    `Skid Siphon`,
    `Exoskeleton`,
    `Sequence Break`,
    `Icy Precipice`,
    `Twin Diamond`,
    `Frozen Quad`,
    `Avoidance`,
    `Rebound Slider`,
    `Hopscotch`,
    `Chain Ricochet`,
    `Wrecking Balls`,
    `Spleef`,
    `Deflection Steps`,
    `Door Dash`,
    `Drill Bits`,
    `Polymer`,
    `Typewriter`,
    `Chroma-Comb`,
    `Recolor`,
    `Imbalance`,
    `Overhangs`,
    `Down Under`,
    `Falling Ahead`,
    `Waffle Weave`,
    `Currents`,
    `Mainframe`,
    `Random Number Generator`,
    `Duplication`,
    `Bouncy Fortress`,
    `Deformed Popsicle`,
    `Escalade`,
    `Cross Stitch`,
    `Wind the Gap`,
    `Framework`,
    `8 Friends :)`,
    `Carnival Cups`,
    `Slippery Speedway`,
    `Break the Targets`,
    `Closed Circuit`,
    `Leaning Tower`,
    `Skyline`,
    `Frequencies`,
    `Raise the Roof!`,
    `Ice Mining`,
    `Electromagnetism`,
    `Chakrams`,
    `Cables`,
    `Percussion Ensemble`,
    `Pinwheel Tracks`,
    `Salt Factory`,
    `Flip Switches`,
    `Nanobot Invasion`,
    `Palette Swap`,
    `Chain Scoop`,
    `Drawbridges`,
    `Hula Hoops`,
    `Foosball`,
    `Membrane`,
    `Shooting Stars`,
    `Neptune's Wrath`,
    `Ring Scrambler`,
    `Gamma Burst`,
    `Scaffolding`,
    `Rapids`,
    `Ziplines`,
    `Butterfly`,
    `White Dwarf`,
    `Impact`,
    `Bouncy Bubble Land`,
    `Balloon Fleet`,
    `Solar Orbit`,
    `Deep Space`,
    `Singularity`,
    `Glitch Anomaly`,
    `Meta Merger`,
    `Pulsar`,
    `Cosmic Genesis`,
    `Stardust Haven`,
    `Hey Goobz Play SMAL`,
    `Eddy's Controller`,
    `Hungry`,
    `Awesome Sauce`,
    `Zorp Stairs`,
    `His Name is Jeffery`,
    `Worlds Hardest Game`,
    `Dwait Epic`,
    `Normal Postmodern`,
    `Normal Simple`,
    `Jolt Channel Die`,
    `Hey Eddy Play Fun House`,
    `Bruh Cubes`,
    `Mess of Cubes`,
    `POV: Ur a GD Cube`,
    `Hey Steven Play Terminal Veloci`,
    `Checker Carpet Slide`,
    `The Slope`,
    `Giant Enemy Bruh 2`,
    `Your Credit Card Information`,
    `Ice Castle Rink`,
    `Ice Hills`,
    `Pogo Puzzle`,
    `Tick-Flip`,
    `Freeway Grid`,
    `Cookie Jump Downhill`,
    `Bracelet Ice`,
    `Bounce Spikes`,
    `Ricochet`,
    `Sugar Cone Ice`,
    `Gravity Stuff Test`,
    `Jello Bounce`,
    `Bounce Bevel Squares`,
    `Carnival Cups`,
    `Bounce Edge Box`,
    `Hammer Pipes`,
    `Wind Chimes`,
    `Loop Launch`,
    `Grasshopper`,
    `Brutalism`,
    `Figure 8`,
    `Stellar W2 Draft`,
    `Chain Link`,
    `Double Loop`,
    `Library`,
    `Hill Concept`,
    `Ant Farm`,
    `Auto Door Launcher`,
    `Buggy Raft Concept`,
    `Plank Shuffle`,
    `Drop Hit Puzzle`,
    `Wormhole U-Turn Puzzle`,
    `Bounce Wave`,
    `Frypan`,
    `Revolution Concept`,
    `This Was Gonna Be a Whole City`,
    `Cross Fling`,
    `Nambo Can't Model a Net`,
    `Node Bounce`,
    `Bracket Bash`,
    `Pick Your Poison Test`,
    `Spaghettios`,
    `Strings`,
    `Angle Redirect`,
    `Kickflip Ice Up`,
    `Slope Step Overclock`,
    `Trainwreck`,
    `Wormhole Bouncepad Launcher`,
    `Tusk Tubes`,
    `Buffer`,
    `Sweepers Bunch  Park`,
    `Staircase Slopes`,
    `Sky Grid`,
    `Rorrim Launcher`,
    `Concentric Bowls`,
    `Timber`,
    `Comeback Run`,
    `Gears`,
    `Downhill Rinks`,
    `Ring Helix`,
    `Run-Up Bounce`,
    `Key Launch`,
    `Bevel Park`,
    `Checker Fractal`,
    `Puddles`,
    `Wall Bounce Low`,
    `Ice Wall Slope`,
    `Pulse Diamond`,
    `Candy Clog`,
    `Cycle Madness`,
    `Cycle Madness 2`,
    `Sleep Wire`,
    `Stupid Swing Bridges`,
    `Bounce Mechanics`,
    `Ladder Stack`,
    `Optimize!`,
    `Cliptimize!`,
    `Get a Vertex Clip Lol`,
    `Dotted Line`,
    `Debug Spider`,
    `Giant Enemy Bruh`,
    `This Guy Eats a Banana`,
    `66 P's`,
    `Fun Wire Challenge`,
    `Spiky Die`,
    `Lava River Hard`,
    `Bubble Kaizo`,
    `Rock On`,
    `Cranks`,
    `Precession`,
    `Monuments`,
];

const smb2 = [
    `Training`,
    `Gimmick`,
    `Mountain`,
    `Disorder`,
    `3D Maze`,
    `Labyrinth`,
    `Postmodern`,
    `Revolution`,
    `Invisible`,
    `Created By`,
    `Simple`,
    `Hollow`,
    `Bumpy`,
    `Switches`,
    `Bowl`,
    `Floaters`,
    `Slopes`,
    `Sliders`,
    `Spinning Top`,
    `Curve Bridge`,
    `Conveyers`,
    `Bumpy Check`,
    `Alternative`,
    `Junction`,
    `Bead Screen`,
    `Fluctuation`,
    `Folders`,
    `Quick Turn`,
    `Linear Seesaws`,
    `Birth`,
    `Banks`,
    `Eaten Floor`,
    `Hoppers`,
    `Coaster`,
    `Board Park`,
    `Swell`,
    `Gravity Slider`,
    `Inchworms`,
    `Totalitarianism`,
    `Leveler`,
    `Organic Form`,
    `Reversible Gear`,
    `Stepping Stones`,
    `Dribbles`,
    `U.R.L.`,
    `Mad Rings`,
    `Curvy Options`,
    `Twister`,
    `Downhill`,
    `Rampage`,
    `Pro Skaters`,
    `Giant Comb`,
    `Beehive`,
    `Dynamic Maze`,
    `Triangle Holes`,
    `Launchers`,
    `Randomizer`,
    `Coin Slots`,
    `Seesaw Bridges`,
    `Arthropod`,
    `Auto Doors`,
    `Heavy Sphere`,
    `Stagger`,
    `U.F.O.`,
    `Ring Bridges`,
    `Domes`,
    `Amida Lot`,
    `Long Slider`,
    `Grid Bridge`,
    `Teapot`,
    `Wormhole`,
    `Free Fall`,
    `Melting Pot`,
    `Mad Shuffle`,
    `Partition`,
    `Jump Machine`,
    `Zigzag Slope`,
    `Tower`,
    `Toggle`,
    `Pachinko`,
    `Combination`,
    `Punched Seesaws`,
    `Opera`,
    `Brandished`,
    `Tiers`,
    `Cliffs`,
    `Narrow Peaks`,
    `Detour`,
    `Switch Inferno`,
    `Earthquake`,
    `Spiral Bridge`,
    `Wavy Option`,
    `Obstacle`,
    `Domino`,
    `Sieve`,
    `Flock`,
    `Double Spiral`,
    `Hierarchy`,
    `8 Bracelets`,
    `Banana Hunting`,
    `Pistons`,
    `Soft Cream`,
    `Momentum`,
    `Entangled Path`,
    `Totters`,
    `Vortex`,
    `Warp`,
    `Trampolines`,
    `Swing Shaft`,
    `Fighters`,
    `Serial Jump`,
    `Cross Floors`,
    `Spinning Saw`,
    `Chipped Pipes`,
    `Flat Maze`,
    `Guillotine`,
    `Cork Screw`,
    `Orbiters`,
    `Twin Basin`,
    `Air Hockey`,
    `Charge`,
    `Strata`,
    `Puzzle`,
    `Giant Swing`,
    `5 Drums`,
    `Free Throw`,
    `Pendulums`,
    `Conical Slider`,
    `Construction`,
    `Train Worm`,
    `Centrifugal`,
    `Swing Bridges`,
    `Cylinders`,
    `Passage`,
    `Notch`,
    `Intermittent`,
    `Long Torus`,
    `Spasmodic`,
    `Double Twin`,
    `Clock Face`,
    `Variable Width`,
    `Striker`,
    `Ooparts`,
    `Planets`,
    `Sliced Cheese`,
    `8 Seesaws`,
    `Synchronized`,
    `Helix`,
    `Dizzy System`,
    `Nintendo`,
];

const launch = [
    `Banana Savannah`,
    `Shuffle`,
    `Banana Pipe`,
    `Dominion`,
    `Infinity`,
    `Solar Panels`,
    `Crease`,
    `Slippery Slopes`,
    `Shake Weights`,
    `Jungle Gym`,
    `Crash Simulator`,
    `Crossword`,
    `Death Blocks`,
    `S-Clip`,
    `Crusher`,
    `Locksmith`,
    `Greenhorn`,
    `Cartons`,
    `Powered Rail`,
    `Pow!`,
    `Quadrangle`,
    `Delivery`,
    `Solar Flare`,
    `Firework`,
    `Reel`,
    `Runway`,
    `Fissure`,
    `Swing Set`,
    `Hummingbirds`,
    `Revitalize`,
    `Arch`,
    `Horseshoe`,
    `Teetering Towers`,
    `Udon`,
    `Diamondback`,
    `Carabiner`,
    `Hatches`,
    `Rickety Ridge`,
    `5 Golden Rings`,
    `High Striker`,
    `Distort`,
    `Squeeze`,
    `Weirs`,
    `Hurdles`,
    `Tablecloth`,
    `Drop Cycle`,
    `Hole in the Wall`,
    `Spires`,
    `Equinox`,
    `Influx`,
    `Trailblazing`,
    `Lava Plume`,
    `Surcharge`,
    `Performance`,
    `Sanguine`,
    `Hurricane`,
    `Surveillance`,
    `Heist`,
    `Swerve Highway`,
    `Battle Canyon`,
    `Propulsion`,
    `Contractor`,
    `Clockwork`,
    `Drills`,
    `Harmonic`,
    `Smartphone`,
    `Sustain`,
    `Zap!`,
    `Pulley`,
    `Nuclear`,
    `Hydroplane`,
    `Altitude`,
    `Circumvent`,
    `Toblerone`,
    `Eaves`,
    `1080`,
    `Influence`,
    `Toboggan`,
    `Icicle Shaft`,
    `Do You Wanna Build A Snowman?`,
    `Super Monkey Maker`,
    `Interchange`,
    `Infiltrate`,
    `Galeforce`,
    `Commingle Soiree`,
    `Arbor`,
    `Comets`,
    `Blast Off!`,
    `Rotary Orbit`,
    `Matrix`,
    `Turbulent`,
    `Haywire`,
    `Collapse`,
    `Apparition`,
    `Invert`,
    `Overheat`,
    `Spindle`,
    `Defunct`,
    `Escape Sequence`,
    `Extraction`,
    `Eddy's Bruh Hunt : D`,
    `Walkr69_`,
    `Namb69`,
    `6IKEx9IKEne`,
    `Debris`,
    `Wreckage`,
    `Melty`,
    `Eclipse`,
    `Majestic`,
    `Egress`,
    `Parallel`,
    `Planar Shift`,
    `Strife`,
    `Distortion`,
    `Emergence`,
    `Conquest`,
    `Fervor`,
    `Resurgent`,
    `Vigor`,
    `Determination`,
    `Faith`,
    `Conviction`,
    `Resolute`,
    `Journey's End`,
];

const invasion = [
    `Duality`,
    `5 Star Level`,
    `Show Off`,
    `Scavenger`,
    `Gravity`,
    `Semisolid Maze`,
    `Lane Switching`,
    `Training Platforms`,
    `Ring Bar`,
    `2-D`,
    `Infinity Mirror`,
    `Mini Puzzle`,
    `Beeline`,
    `Dismantled Pedestal`,
    `Captivity`,
    `Run Down Hall`,
    `Optional Rebound`,
    `Summit`,
    `Risky Ridges`,
    `House Hopping`,
    `Sunny Slope`,
    `Crown`,
    `Jungle Gym`,
    `Bulrush Pond`,
    `Mosquitoes`,
    `Sublevels`,
    `Woodpecker`,
    `Nice Shot!`,
    `Treacherous Tightrope`,
    `Tree Fort`,
    `Marbling`,
    `Scattershot`,
    `Filter`,
    `Lifts`,
    `Vectors`,
    `Delayed Reaction`,
    `Gamble`,
    `Barbed Jaw`,
    `Ice Skate Park`,
    `Personal Space`,
    `2 Staircases`,
    `Sky Trampolines`,
    `Umbrellas`,
    `Superpower`,
    `Triple Flipper Threat`,
    `Slippery When Wet`,
    `Too Many Wires`,
    `Ruffle`,
    `Deranged Toy`,
    `Water Bird`,
    `Culling Plain`,
    `Digital`,
    `Outrun`,
    `Heat Waves`,
    `Scorpion`,
    `Triangle Walk`,
    `Unstable Trail`,
    `Recoil Bunkers`,
    `Volcano Escape`,
    `Rotisserie`,
    `Collapsing Bridge`,
    `Skill Tree`,
    `Ritual`,
    `Double Bounce Ban`,
    `Energy Halo`,
    `Hideout`,
    `Speed Tech`,
    `Crumbled Castle`,
    `Worship`,
    `Drills`,
    `Unconventional Combo`,
    `Minimalist Cactus`,
    `Wall Running`,
    `Hasty Slither`,
    `Ring Of Agency`,
    `Whiskers`,
    `Electric Fence`,
    `Leak`,
    `Transform`,
    `Neon`,
    `Checker Interceptor`,
    `Seesaw Cockfight`,
    `Entity`,
    `Disperse`,
    `Swipe`,
    `Counterweight`,
    `Sneaky Steps`,
    `Heavy Weight`,
    `Sci-Fi Highway`,
    `Comeback`,
    `Difficulty Spikes`,
    `Torture Chamber`,
    `Proportional Editing`,
    `Relapse`,
    `Front And Back`,
    `8 Fun Bracelets`,
    `Teleportation`,
    `Arbitrary Restriction`,
    `Afterlife`,
    `Sensory Overload`,
];

const deluxe = [
    `Diamond`,
    `Edge`,
    `Escalators`,
    `Hairpin`,
    `Wide Bridge`,
    `Serpentine`,
    `Swirl`,
    `Spinning Top`,
    `Steps`,
    `Dormant Pits`,
    `Curve Bridge`,
    `Hollow`,
    `Switches`,
    `Banks`,
    `Blocks`,
    `Composition`,
    `Dynamo`,
    `Hoppers`,
    `Jump Single`,
    `Exam-A`,
    `Lunchbox`,
    `Swell`,
    `Gravity Slider`,
    `Gutter`,
    `Inchworms`,
    `Bump`,
    `Totalitarianism`,
    `Walking`,
    `Jolt Channel`,
    `Alternative`,
    `Eaten Floor`,
    `Coaster`,
    `Organic Form`,
    `Repulse`,
    `Intertial`,
    `Octagonal`,
    `Stepping Stones`,
    `Narrow Bridge`,
    `Break`,
    `U.R.L.`,
    `Sewer`,
    `Mad Rings`,
    `Curvy Options`,
    `Arc Bridges`,
    `Curves`,
    `Downhill`,
    `Veer Field`,
    `Junction`,
    `Reversible Gear`,
    `Dribbles`,
    `Pro Skaters`,
    `Blocks Slim`,
    `Jump Ramp`,
    `Hounds Tooth`,
    `Beehive`,
    `Choice`,
    `Bowl`,
    `Triangle Holes`,
    `D.N.A.`,
    `Launchers`,
    `Randomizer`,
    `Building`,
    `Coin Slots`,
    `Jumpies`,
    `Seesaw Bridges`,
    `Stoppers`,
    `Scrolls`,
    `Arthropod`,
    `Giant Comb`,
    `Dynamic Maze`,
    `Wormhole`,
    `Floor Bent`,
    `Free Fall`,
    `Melting Pot`,
    `Exam-B`,
    `Mad Shuffle`,
    `Bead Screen`,
    `Jump Double`,
    `Jump Machine`,
    `Middle Jam`,
    `Zigzag Slope`,
    `Ant Lion`,
    `Tower`,
    `Collapse`,
    `Toggle`,
    `Swing Bar`,
    `Fluctuation`,
    `Labyrinth`,
    `Conveyor`,
    `Chaser`,
    `Combination`,
    `Spiral`,
    `Punched Seesaws`,
    `Opera`,
    `Spiky`,
    `Brandished`,
    `Tiers`,
    `Polar`,
    `Cliffs`,
    `Ruin`,
    `Narrow Peaks`,
    `Branch`,
    `Detour`,
    `Overturn`,
    `Switch Inferno`,
    `Excursion`,
    `Folders`,
    `Dodecagon`,
    `Wavy Jump`,
    `Unrest`,
    `Exam-C`,
    `Wavy Option`,
    `Obstacle`,
    `Tracks`,
    `Domino`,
    `Sieve`,
    `Gears`,
    `Flock`,
    `Destruction`,
    `Double Spiral`,
    `Invasion`,
    `Hierarchy`,
    `Diving`,
    `8 Bracelets`,
    `Floor Slant`,
    `Quick Turn`,
    `Tram`,
    `Skeleton`,
    `Downhill Hard`,
    `Pistons`,
    `Swing Bar Long`,
    `Soft Cream`,
    `Momentum`,
    `Twin Attacker`,
    `Entangled Path`,
    `Totters`,
    `Snake`,
    `Vortex`,
    `Wind`,
    `Warp`,
    `Sliders`,
    `Trampolines`,
    `Fall Down`,
    `Sing Shaft`,
    `Twin Cross`,
    `Linear Seesaws`,
    `Spiral Hard`,
    `Paper Work`,
    `Sega Logo`,
    `Serial Jump`,
    `Conveyor Parts`,
    `Cross Floors`,
    `Spinning Saw`,
    `Curvature`,
    `Chipped Pipes`,
    `Flat Maze`,
    `Drum`,
    `Speedy Jam`,
    `Quake`,
    `Cassiopeia`,
    `Pirates`,
    `Gaps`,
    `Ant Lion Super`,
    `Bowl Open`,
    `Carpet`,
    `Mixer`,
    `Rings`,
    `Stairs`,
    `Clover`,
    `Coffee Cup`,
    `Metamorphosis`,
    `Checker`,
    `Ridge`,
    `Plain`,
    `Bonus Basic`,
    `Dormant Pits`,
    `Composition`,
    `Dynamo`,
    `Lunchbox`,
    `Gutter`,
    `Steps`,
    `Blocks`,
    `Jump Single`,
    `Exam-A`,
    `Sliders`,
    `Scabrous`,
    `Jolt Channel`,
    `Intertial`,
    `Octagonal`,
    `Sewer`,
    `Arc Bridges`,
    `Veer Field`,
    `Teeter`,
    `Stream`,
    `Catwalk`,
    `Catapult`,
    `Blur Bridge`,
    `Hitter`,
    `AV Logo`,
    `Rhombus`,
    `Buoy`,
    `Coil Ramp`,
    `Millefeuille`,
    `Waver`,
    `Fat Seesaw`,
    `Treads`,
    `Bump`,
    `Walking`,
    `Repulse`,
    `Narrow Bridge`,
    `Break`,
    `Curves`,
    `Downhill`,
    `Blocks Slim`,
    `Choice`,
    `Bonus Wave`,
    `Jump Ramp`,
    `Hounds Tooth`,
    `D.N.A.`,
    `Building`,
    `Scrolls`,
    `Cliff`,
    `Concentric`,
    `Stadium`,
    `Dungeon`,
    `Bonus Grid`,
    `Bowl`,
    `Jumpies`,
    `Stoppers`,
    `Floor Bent`,
    `Conveyor`,
    `Exam-B`,
    `Chaser`,
    `Jump Double`,
    `Middle Jam`,
    `Twister`,
    `Ant Lion`,
    `Collapse`,
    `Swing Bar`,
    `Labyrinth`,
    `Spiral`,
    `Wavy Jump`,
    `Spiky`,
    `Unrest`,
    `Polar`,
    `Paraboloid`,
    `Spatiotemporal`,
    `Hard Hitter`,
    `Puzzle`,
    `Polar Large`,
    `Chaos`,
    `Tilt Maze`,
    `Fractal`,
    `Hills`,
    `Ruin`,
    `Branch`,
    `Overturn`,
    `Excursion`,
    `Dodecagon`,
    `Exam-C`,
    `Skeleton`,
    `Tracks`,
    `Downhill Hard`,
    `Bonus Bumpy`,
    `Tower`,
    `Toggle`,
    `Combination`,
    `Bonus Hunting`,
    `Gears`,
    `Destruction`,
    `Invasion`,
    `Diving`,
    `Floor Slant`,
    `Tram`,
    `Swing Bar Long`,
    `Paper Work`,
    `Twin Attacker`,
    `Pachinko`,
    `Sega Logo`,
    `Snake`,
    `Wind`,
    `Fall Down`,
    `Twin Cross`,
    `Spiral Hard`,
    `Conveyor Parts`,
    `Gaps`,
    `Curvature`,
    `Ant Lion Super`,
    `Drum`,
    `Speedy Jam`,
    `Quake`,
    `Cassiopeia`,
    `Pirates`,
    `Bowl Open`,
    `Shelter`,
    `Checker`,
    `Carpet`,
    `Ridge`,
    `Mixer`,
    `Rings`,
    `Stairs`,
    `Clover`,
    `Coffee Cup`,
    `Metamorphosis`,
    `Mobius`,
    `Asterisk`,
    `Breathe`,
    `Ferris Wheel`,
    `Factory`,
    `Curl Pipe`,
    `Magic Hand`,
    `Sanctuary`,
    `Daa Loo Maa`,
    `Wave Master`,
    `Fan Master`,
    `Stamina Master`,
    `Spring Master`,
    `Dance Master`,
    `Roll Master`,
    `Edge Master`,
    `Dodge Master`,
    `Bridge Master`,
    `Monkey Master`,
    `Destiny`,
    // `Cascade`, downhill
    // `Tub`, bowl
    // `Rising Inclines` slopes
    // `Twist & Spin`, twister
    // `Windy Slide`, sliders
    // `Square Dance`, puzzle
    // `Crazy Maze`, labyrinth
];

const _651 = [
    `Nest`,
    `Paper`,
    `Meadow`,
    `Container`,
    `Screen Tearing`,
    `Restless`,
    `Chills`,
    `Rerun`,
    `Knot`,
    `Ring Attack`,
    `Jump Simulator`,
    `Capsule`,
    `Bunkers`,
    `Silly Slalom`,
    `Stencil`,
    `Dome`,
    `Fun House`,
    `Beads`,
    `Peaking`,
    `Crystal Cave Museum`,
    `Commitment`,
    `Bleachers`,
    `Corner Block`,
    `Saw`,
    `Stamps`,
    `Slicer`,
    `Gyration`,
    `Waves`,
    `Hidden Room`,
    `Descent`,
    `Crosshair`,
    `Measuring Spoons`,
    `Launcher`,
    `Feedback Loop`,
    `Trypophobia`,
    `Collectathon`,
    `Scoop`,
    `Tail`,
    `Valves`,
    `Waveform`,
    `Staircase`,
    `Railroad`,
    `Raft`,
    `Area`,
    `Custom Road`,
    `Repellent`,
    `Changeover`,
    `Ribbon`,
    `Quadripartite`,
    `Tides`,
    `Wormhole Practice`,
    `Timing`,
    `Mystery Box`,
    `Return`,
    `Steps`,
    `Drop In`,
    `Volte-Face`,
    `Antigravity`,
    `Clothesline`,
    `Architect`,
    `Cards`,
    `Valleys`,
    `Gravity Jumper`,
    `Reflection`,
    `Trap Doors`,
    `Iced Hive`,
    `Device`,
    `Pour`,
    `Pull`,
    `Mini Map Assist`,
    `Axis Maze`,
    `Warp Pipes`,
    `Turn`,
    `Overcorrection`,
    `Sewing`,
    `Jumpscare`,
    `Oscillating Overpass`,
    `I-Beams`,
    `Warp Heights`,
    `Checker Roll`,
    `Elevators`,
    `Distortion`,
    `Picture Frames`,
    `Network`,
    `Lava River`,
    `Stream`,
    `Ladder`,
    `Pacing`,
    `Platformer`,
    `Technique`,
    `Sleekhopper HD`,
    `Smile`,
    `Crank`,
    `Birds`,
    `Unlock`,
    `Frame Windows`,
    `Desync`,
    `Exam-651`,
    `Stress Test`,
    `Antlers`,
    `Slope Mechanics`,
    `Lucky WR`,
]

const combined = []
    .concat(smb2)
    .concat(gaiden)
    .concat(stardust)
    .concat(launch)
    .concat(invasion)
    .concat(deluxe)
    .concat(_651);

const stagenames = [...new Set(combined)];

export default stagenames;
